import React, { useEffect } from "react";
import { navigate } from "gatsby";
import SEO from "../components/seo";
import LinearProgress from "@material-ui/core/LinearProgress";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const styles = {
  display: "grid",
  placeItems: "center",
  height: "100vh",
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#720000",
      contrastText: "#fff",
    },
  },
});

export default function Index() {
  useEffect(() => {
    navigate("/coming-soon/");
  }, []);

  return (
    <>
      <SEO title="Inicio"/>
      <ThemeProvider theme={theme}>
        <div style={styles}>
          <div>
            <h1 style={{ marginBottom: "2rem" }}>Cargando...</h1>
            <LinearProgress color="primary" />
          </div>
        </div>
      </ThemeProvider>
    </>
  );
}
